import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    Card,
    CardBody,
    Label,
    Input,
    FormFeedback
} from "reactstrap"
import MetaTags from 'react-meta-tags'
import LoadingContainer from "../Utility/Helpers/LoadingContainer"
import {DIALOG_TYPE, HTTP_RESPONSE} from "../Utility/Helpers/constants"
import DialogBoxComponent from "../Utility/Helpers/DialogBoxComponent"
import Breadcrumbs from "../Common/Breadcrumb"
import {useFormik} from "formik"
import * as Yup from "yup"
import Autocomplete from "../helpers/select.autocomplete.no-auth";
import AutocompleteSelectType from "../helpers/Form/AutocompleteSelectType";
import HoraireRepository from "./Repository/HoraireRepository";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal'

import Basic from "../Basic";

const Horaires = (props) => {

    let {id = 0} = useParams();

    const [resources, setResources] = useState([])
    const [horaires, setHoraires] = useState([])

    const [loading, setLoading] = useState(true);
    const [dialog, setDialog] = useState({
        show: false,
        onConfirm: () => {
        },
        handleClose: () => {
        },
        message: "",
        type: DIALOG_TYPE.ALERT
    });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: false,
        initialValues: {
            date: '',
            employeAffectationPositionId: null,
            client:null,
            lieuAffectation:"Lieu",
            employe:null,
            startTime: '',
            endTime: '',
            pause: 0,
        },
        validationSchema: Yup.object({
            date: Yup.date().required("Champ requis"),
            employeAffectationPositionId: Yup.object().required('Champ requis'),
            startTime: Yup.string().required('Champ requis'),
            endTime: Yup.string().required('Champ requis'),
            pause: Yup.number().required('Champ requis')
        }),
        onSubmit: (values) => {
            setLoading(true);

            let tmp = JSON.parse(JSON.stringify(values));

            console.log("Unprocessed:",JSON.parse(JSON.stringify(values)))

            if(tmp.employeAffectationPositionId != null){
                tmp.employeAffectationPositionId=tmp.employeAffectationPositionId.id;
            }else{
                delete tmp["employeAffectationPositionId"]
            }
            
            if(tmp.startTime.split(":").length<3){
                tmp.startTime= tmp.date+ "T" + tmp.startTime + ":00+00:00"
                tmp.endTime= tmp.date+ "T" + tmp.endTime + ":00+00:00"
            }else{
                tmp.startTime= tmp.date+ "T" + tmp.startTime + "+00:00"
                tmp.endTime= tmp.date+ "T" + tmp.endTime + "+00:00"
            }

            tmp.date= tmp.date+"T00:00:00+00:00"

            delete tmp["client"]
            delete tmp["employe"]
            delete tmp["lieuAffectation"]
            console.log("processed:",tmp)
            
            HoraireRepository.flush({
                ...tmp

            }).then(item => {
                if(item.error){
                    handleCloseFormModal()
                    catchError({
                        status : item.error.code,
                        message: item.error.message
                    })
                }else{
                    setLoading(false);
                    handleCloseFormModal()
                    loadData()
                }
            }).catch(response => {
                catchError(response);
            });
        }
    });

    useEffect(() => {
        if (id) {
            setLoading(true);
            HoraireRepository.find(id).then( async (item) => {
                if(item.employeAffectationPositionId){
                    item.employeAffectationPositionId = item.employeAffectationPosition;
                }else{
                    delete item["employeAffectationPositionId"];
                }

                validation.setValues({...item});

                setLoading(false);
            }).catch(response => {
                catchError(response);
            });
        } else {
            setLoading(false);
        }

        loadData()
        /*let timer = setInterval(()=> loadData(),5000)

        return ()=> {clearInterval(timer)}*/
    }, []);

    const getHoursAndMinutes = (time) =>{
        let parts = time.split(":")
        return parts[0] + ":" + parts[1]
    }
    const loadData = ()=>{
        setResources([]);
        setHoraires([])
        HoraireRepository.findAll().then( async (response)=> {
    
            let employes = [],  seenEmployes = [], currentEmploye
        
            for (let i=0; i< response.items.length; i++){
                currentEmploye = response.items[i].employeAffectationPosition.employeAffectation.employe
                
                if( seenEmployes.find(a=>{return a===currentEmploye.id}) === undefined){
                    seenEmployes.push(currentEmploye.id)
                    employes.push({
                        id: currentEmploye.id,
                        name: currentEmploye.name,
                        data:{
                            employeAffectation: {
                                id: response.items[i].employeAffectationPosition.employeAffectation.id,
                                employeName: response.items[i].employeAffectationPosition.employeAffectation.employe.name
                            },
                            lieuAffectation: {
                                id: response.items[i].employeAffectationPosition.employeAffectation.lieuAffectation.id,
                                name: response.items[i].employeAffectationPosition.employeAffectation.lieuAffectation.name,
                            },
                            client: {
                                id: response.items[i].employeAffectationPosition.employeAffectation.lieuAffectation.client.id,
                                name: response.items[i].employeAffectationPosition.employeAffectation.lieuAffectation.client.name
                            }
                        }   
                    })
                }
            }
        
            // Charge les horaires
            let events = response.items.map((horaire, idx)=>{
                //let date = (new Date(horaire.date)).toLocaleDateString() + " "
                let date = horaire.date.split("T")[0] + " "
                let associatedPosition = {
                    id: horaire.employeAffectationPosition.id,
                    positionName: horaire.employeAffectationPosition.position.name
                }
                return {
                    id: idx,
                    start: date + horaire.startTime,
                    end: date + horaire.endTime,
                    resourceId: horaire.employeAffectationPosition.employeAffectation.employe.id,
                    title: getHoursAndMinutes(horaire.startTime) + " à " + getHoursAndMinutes(horaire.endTime) + " - "+ horaire.employeAffectationPosition.employeAffectation.lieuAffectation.name,
                    resizable: true,
                    movable: true,
                    isConges: false,
                    associatedPosition: associatedPosition,
                    horaireId: horaire.id,
                    pause : horaire.pause
                }
            })

            // Charge les congés
            let conges = await HoraireRepository.findConges();

            for(let i =0; i< conges.totalCount;i++){
                //let date = (new Date(conges.items[i].date)).toLocaleDateString() + " "
                let date = conges.items[i].date.split("T")[0] + " "
                events.push({
                    id: events.length,
                    start: date + conges.items[i].startTime,
                    end: date + conges.items[i].endTime,
                    resourceId: conges.items[i].employe.id,
                    title: "En Congés",
                    resizable: false,
                    movable: false,
                    bgColor: "gray",
                    isConges: true,
                    congeId: conges.items[i].id
                })
            }
            setResources(employes);
            setHoraires(events)
        })
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        validation.handleSubmit();
    };

    const catchError = (response) => {
        const currentDialog = {
            show: true,
            onConfirm: () => closeDialogModal(),
            handleClose: () => closeDialogModal(),
            type: DIALOG_TYPE.ALERT
        };
        let status = HTTP_RESPONSE.HTTP_NOT_FOUND;
        if(response.status !== undefined){
            status = response.status;
        }else if(response.error !== undefined && response.error.statussku !== undefined){
            status = response.error.statussku;
        }
        switch (status) {
            case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_NOT_FOUND: {
                currentDialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                currentDialog.message = '';
                if (response.data.message) {
                    currentDialog.message = response.data.message;
                }
                break;
            }
            case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
                currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
                if(response.data){
                    if( response.data.error.code==="ER_DUP_ENTRY"){
                        currentDialog.message = "Un sku de produit avec le même sku existe déjà";
                    }else{
                        currentDialog.message = "Veuillez vérifiez les champs du formulaire";
                    }
                }else{
                    currentDialog.message = response.message
                }
                break;
            }
            default:
        }
        setDialog(currentDialog);
    };
    
    const closeDialogModal = () => {
        setDialog({...dialog, show: false});
        setLoading(false);
    };

    const onClientSelected = (e) =>{
        validation.handleChange(e)
        validation.values.lieuAffectation = "";
    }

    const onPositionSelected = (e) =>{
        validation.handleChange(e)
    }

    const [showFormModal, setShowFormModal] = useState(false)
    const handleCloseFormModal = () => setShowFormModal(false);
    const handleShowFormModal = () => setShowFormModal(true);

    const addNewHoraire = (schedulerData, slotId, slotName, start, end, type, item)=>{
        console.log(`{slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`)
        let currentResource = schedulerData.resources.filter((res)=>res.id===slotId)[0].data
        validation.setValues({
            ...validation.values ,
            client: currentResource.client,
            lieuAffectation: currentResource.lieuAffectation,
            employe: currentResource.employeAffectation,
            date: moment(start.split(" ")[0]).format().split("T")[0],
            startTime: "08:00:00",
            endTime: "16:00:00",
            pause: 30,
            id: 0
        })
        handleShowFormModal()
    }

    const editHoraire = (schedulerData, event)=>{
        let currentResource = schedulerData.resources.filter((res)=>res.id===event.resourceId)[0].data
        validation.setValues({
            ...validation.values ,
            client: currentResource.client,
            lieuAffectation: currentResource.lieuAffectation,
            employe: currentResource.employeAffectation,
            date: moment(event.start.split(" ")[0]).format().split("T")[0],
            startTime: event.start.split(" ")[1],
            endTime: event.end.split(" ")[1],
            employeAffectationPositionId: event.associatedPosition,
            pause: event.pause,
            id:event.horaireId
        })
        handleShowFormModal()
    }

    const deleteHoraire = (event)=>{
        setLoading(true);
        HoraireRepository.remove(event.horaireId).then(item => {
            setLoading(false)
            loadData()
        }).catch(response => {
            catchError(response);
        });
    }

    const [filtreClient, setFiltreClient] = useState(null);

    const onFiltreClientSelected = (e) =>{
        setFiltreLieu(null)
        setFiltreClient(e.target.value)
    }

    const [filtreLieu, setFiltreLieu] = useState(null);
    const onFiltreLieuSelected = (e) =>{        
        setFiltreLieu(e.target.value)
    }

    const newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        addNewHoraire(schedulerData, slotId, slotName, start, end, type, item)
    }

    const eventClicked = (schedulerData, event) => {
        editEvent(schedulerData, event)
    };
    
    let eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
        let titleParts = title.split("-")

        let processedTitle = title
        if (titleParts.length > 1 ){
            processedTitle = titleParts.slice(1).join("-")
        }
        return(
            <div style={{width: '300px'}}>
                <div className="d-flex w-100 gap-2 align-items-center">
                    <div>
                        <div className="status-dot" style={{backgroundColor: statusColor}} />
                    </div>
                    <div className="flex-grow-1 overflow-text">
                        <span className="header2-text" title={title}>{processedTitle}</span>
                    </div>
                </div>
                <div className="d-flex w-100 gap-2 align-items-center">
                    <div>
                        <div />
                    </div>
                    <div className="flex-grow-1">
                        <span className="header1-text">{start.format("HH:mm")} - {end.format("HH:mm")}</span>
                    </div>
                </div>
                <div className="d-flex w-100 gap-2 align-items-center">
                    <div>
                        <div />
                    </div>
                    <div className="flex-grow-1">
                        <button className="btn btn-link" onClick={()=>editEvent(schedulerData, eventItem)}> Modifier</button>
                        <button className="btn btn-link" onClick={ ()=>deleteEvent(schedulerData, eventItem)}>Supprimer</button>
                    </div>
                </div>
            </div>
        );
    }

    const editEvent = (schedulerData, eventItem) => {
        if(eventItem.isConges) {
            alert(`Modification des conges en cours d'implémentation`);
        }else{
            editHoraire(schedulerData, eventItem)
        }
    }

    let deleteEvent = (schedulerData, eventItem) => {
        if(eventItem.isConges) {
            alert(`Modification des conges en cours d'implémentation`);
        }else{
            deleteHoraire(eventItem)
        }        
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Horaire - Edition</title>
                </MetaTags>
                <Container fluid={true}>
                    <div className="mb-4">
                        <Breadcrumbs title="File System" breadcrumbItem="Horaire - Edition"/>
                    </div>
                    <LoadingContainer className="mb-4" loading={loading}>
                        <Row>
                            <Col className="col-12">
                                
                                <Modal show={showFormModal} onHide={handleCloseFormModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Nouvelle Horaire</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                            <Form onSubmit={(e) => handleSubmitForm(e)}>
                                                <div className="row">
                                                    <div className="mb-3">
                                                        <Label
                                                            className="form-label">Client </Label>
                                                        <AutocompleteSelectType
                                                            id={"client"}
                                                            name={"client"}
                                                            multiple={false}
                                                            value={validation.values.client || ""}
                                                            defaultOptions={true}
                                                            remoteChoices={(inputValue) => Autocomplete.clients(inputValue)}
                                                            className="input-form"
                                                            choiceAttr="name"
                                                            placeholder={"Choisir un Client ..."}
                                                            onChange={(e) =>  onClientSelected(e)}
                                                            onBlur={validation.handleBlur}
                                                            defaultCss={""}
                                                            error={
                                                                validation.touched.client && validation.errors.client ? true : false
                                                        }/>
                                                        {validation.touched.client && validation.errors.client ? (
                                                            <FormFeedback type="invalid">{validation.errors.client}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label
                                                            className="form-label">Lieu Affectation </Label>
                                                        <AutocompleteSelectType
                                                            id={"lieuAffectation"}
                                                            name={"lieuAffectation"}
                                                            multiple={false}
                                                            isDisabled={!(validation.values.client && validation.values.client.id)}
                                                            value={validation.values.lieuAffectation}
                                                            remoteChoices={(inputValue) => Autocomplete.lieuxAffectationByClient(validation.values.client?validation.values.client.id : null,inputValue)}
                                                            className="input-form"
                                                            choiceAttr="name"
                                                            placeholder={"Choisir un Lieu d'affectation ..."}
                                                            onChange={(e) => validation.handleChange(e)}
                                                            onBlur={validation.handleBlur}
                                                            defaultCss={""}
                                                            error={
                                                                validation.touched.lieuAffectation && validation.errors.lieuAffectation ? true : false
                                                        }/>
                                                        {validation.touched.lieuAffectation && validation.errors.lieuAffectation ? (
                                                            <FormFeedback type="invalid">{validation.errors.lieuAffectation}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label">Employé</Label>
                                                        <AutocompleteSelectType
                                                            id={"employe"}
                                                            name={"employe"}
                                                            multiple={false}
                                                            value={validation.values.employe || ""}
                                                            isDisabled={!(validation.values.lieuAffectation && validation.values.lieuAffectation.id)}
                                                            remoteChoices={(inputValue) => Autocomplete.employeByLieuAffectation(validation.values.lieuAffectation?validation.values.lieuAffectation.id : null,inputValue)}
                                                            className="input-form"
                                                            choiceAttr="employeName"
                                                            placeholder={"Choisir un Employé ..."}
                                                            onChange={(e) => validation.handleChange(e)}
                                                            onBlur={validation.handleBlur}
                                                            defaultCss={""}
                                                            error={
                                                                validation.touched.employe && validation.errors.employe ? true : false
                                                        }/>
                                                        {validation.touched.employe && validation.errors.employe ? (
                                                            <FormFeedback type="invalid">{validation.errors.employe}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label">Position</Label>
                                                        <AutocompleteSelectType
                                                            id={"employeAffectationPositionId"}
                                                            name={"employeAffectationPositionId"}
                                                            multiple={false}
                                                            isDisabled={!(validation.values.employe && validation.values.employe.id)}
                                                            value={validation.values.employeAffectationPositionId || ""}
                                                            remoteChoices={(inputValue) => Autocomplete.positionsByEmployeAffectation(validation.values.employe?validation.values.employe.id : null,inputValue)}
                                                            className="input-form"
                                                            choiceAttr="positionName"
                                                            placeholder={"Choisir une position ..."}
                                                            onChange={(e) => onPositionSelected(e)}
                                                            onBlur={validation.handleBlur}
                                                            defaultCss={""}
                                                            error={
                                                                validation.touched.employeAffectationPositionId && validation.errors.employeAffectationPositionId ? true : false
                                                        }/>
                                                        {validation.touched.employeAffectationPositionId && validation.errors.employeAffectationPositionId ? (
                                                            <FormFeedback type="invalid">{validation.errors.employeAffectationPositionId}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label">Date</Label>
                                                        <Input
                                                            id="date"
                                                            name="date"
                                                            className="form-control"
                                                            placeholder="Date"
                                                            type="date"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.date }
                                                            invalid={
                                                                validation.touched.date && validation.errors.date ? true : false
                                                            }
                                                        />
                                                        {validation.touched.date && validation.errors.date ? (
                                                            <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Début</Label>
                                                        <Input
                                                            id="startTime"
                                                            name="startTime"
                                                            className="form-control"
                                                            placeholder="Debut"
                                                            type="time"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.startTime }
                                                            invalid={
                                                                validation.touched.startTime && validation.errors.startTime ? true : false
                                                            }
                                                        />
                                                        {validation.touched.startTime && validation.errors.startTime ? (
                                                            <FormFeedback type="invalid">{validation.errors.startTime}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label">Fin</Label>
                                                        <Input
                                                            id="endTime"
                                                            name="endTime"
                                                            className="form-control"
                                                            placeholder="Fin"
                                                            type="time"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.endTime }
                                                            invalid={
                                                                validation.touched.endTime && validation.errors.endTime ? true : false
                                                            }
                                                        />
                                                        {validation.touched.endTime && validation.errors.endTime ? (
                                                            <FormFeedback type="invalid">{validation.errors.tel}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Pause</Label>
                                                        <Input
                                                            id="pause"
                                                            name="pause"
                                                            className="form-control"
                                                            placeholder="Pause"
                                                            type="number"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.pause }
                                                            invalid={
                                                                validation.touched.pause && validation.errors.pause ? true : false
                                                            }
                                                        />
                                                        {validation.touched.pause && validation.errors.pause ? (
                                                            <FormFeedback type="invalid">{validation.errors.pause}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
 
                                                <div className="mt-4 d-flex w-100 justify-content-end gap-4">
                                                    <button
                                                        type="button" onClick={handleCloseFormModal}
                                                        className="btn btn-danger">
                                                        Fermer
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary">
                                                        Enregistrer les modifications
                                                    </button>
                                                </div>
                                            </Form>
                                    </Modal.Body>
                                </Modal>
                                <Card className="col-12" style={{overflow:"scroll"}}>
                                    <CardBody> 
                                        <button type="button" onClick={handleShowFormModal} className="btn btn-primary">Ajouter</button>
                                        <div className="d-flex gap-2 mt-2 mb-4">
                                            <AutocompleteSelectType
                                                multiple={false}
                                                value={filtreClient || ""}
                                                defaultOptions={true}
                                                remoteChoices={(inputValue) => Autocomplete.clients(inputValue)}
                                                className="w-25"
                                                choiceAttr="name"
                                                placeholder={"Filtrer par Client ..."}
                                                onChange={(e) =>  onFiltreClientSelected(e)}
                                                defaultCss={""}
                                            />

                                            <AutocompleteSelectType
                                                multiple={false}
                                                value={filtreLieu || ""}
                                                defaultOptions={true}
                                                isDisabled={!(filtreClient && filtreClient.id)}
                                                remoteChoices={(inputValue) => Autocomplete.lieuxAffectationByClient(filtreClient? filtreClient.id : null,inputValue)}
                                                className="w-25"
                                                choiceAttr="name"
                                                placeholder={"Filtrer par Lieu ..."}
                                                onChange={(e) =>  onFiltreLieuSelected(e)}
                                                defaultCss={""}
                                            />
                                        </div>
                                        <div>
                                            <h3 style={{textAlign: 'center'}}>Horaires</h3>
                                            {
                                                (resources==null || horaires==null)
                                                ?
                                                <p>Loading...</p>
                                                :
                                                <Basic 
                                                    resources={resources} 
                                                    events={horaires} 
                                                    eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
                                                    newEvent={newEvent}
                                                    eventClicked={eventClicked}
                                                />
                                            }
                                            
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </LoadingContainer>
                </Container>
                <DialogBoxComponent
                    handleClose={() => closeDialogModal()}
                    handleConfirm={() => dialog.onConfirm()}
                    show={dialog.show}
                    type={dialog.type}
                    message={dialog.message}
                />
            </div>
        </React.Fragment>
    )
}
export default Horaires
