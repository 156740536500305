import React, {Component} from 'react'
//import moment from 'moment'
//import 'moment/locale/zh-cn';
import Scheduler, {SchedulerData, ViewTypes} from 'react-big-scheduler'
import withDragDropContext from './withDnDContext'
import 'react-big-scheduler/lib/css/style.css'

class Basic extends Component{
    constructor(props){
        super(props);
        let today = (new Date() ).toISOString().split("T")[0]
        let schedulerData = new SchedulerData(today, ViewTypes.Week, false, false, {
            // minuteStep: 15
            checkConflict: false,
            schedulerWidth: "82%",
            views:[
                {
                    viewType: ViewTypes.Week,
                    showAgenda: false,
                    isEventPerspective: false,
                    viewName: "Semaine"
                },
                {
                    viewType: ViewTypes.Month,
                    showAgenda: false,
                    isEventPerspective: false,
                    viewName: "Mois"
                }
            ]
        });
        // schedulerData.localeMoment.locale('en');
        schedulerData.setResources(this.props.resources);
        schedulerData.setEvents(this.props.events);
        this.state = {
            viewModel: schedulerData
        }
    }

    updateData = () =>{
        this.state.viewModel.setResources(this.props.resources)
        this.state.viewModel.setEvents(this.props.events);        
    }

    static getDerivedStateFromProps(props, current_state) {
        return {
            resources: props.resources,
            events: props.events,
            newEvent: props.newEvent,
            eventItemPopoverTemplateResolver: props.eventItemPopoverTemplateResolver
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateData()
    }

    render(){
        const {viewModel} = this.state;
        return (
            <div>
                <div>
                    <Scheduler schedulerData={viewModel}
                               prevClick={this.prevClick}
                               nextClick={this.nextClick}
                               onSelectDate={this.onSelectDate}
                               onViewChange={this.onViewChange}
                               eventItemClick={this.props.eventClicked}
                               viewEventClick={this.ops1}
                               viewEventText="Ops 1"
                               viewEvent2Text="Ops 2"
                               viewEvent2Click={this.ops2}
                               updateEventStart={this.updateEventStart}
                               updateEventEnd={this.updateEventEnd}
                               moveEvent={this.moveEvent}
                               newEvent={this.props.newEvent}
                               eventItemPopoverTemplateResolver={this.props.eventItemPopoverTemplateResolver}
                    />
                </div>
            </div>
        )
    }

    prevClick = (schedulerData)=> {
        schedulerData.prev();
        schedulerData.setEvents(this.props.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    nextClick = (schedulerData)=> {
        schedulerData.next();
        schedulerData.setEvents(this.props.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(this.props.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(this.props.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    ops1 = (schedulerData, event) => {
        alert(`You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`);
    };

    ops2 = (schedulerData, event) => {
        alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`);
    };

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        let newFreshId = 0;
        schedulerData.events.forEach((item) => {
            if(item.id >= newFreshId)
                newFreshId = item.id + 1;
        });

        let newEvent = {
            id: newFreshId,
            title: 'New event you just created',
            start: start,
            end: end,
            resourceId: slotId,
            bgColor: 'purple'
        }
        schedulerData.addEvent(newEvent);
        this.setState({
            viewModel: schedulerData
        })
    }

    updateEventStart = (schedulerData, event, newStart) => {
        schedulerData.updateEventStart(event, newStart);
        this.setState({
            viewModel: schedulerData
        })
    }

    updateEventEnd = (schedulerData, event, newEnd) => {
        schedulerData.updateEventEnd(event, newEnd);
        this.setState({
            viewModel: schedulerData
        })
    }

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        schedulerData.moveEvent(event, slotId, slotName, start, end);
        this.setState({
            viewModel: schedulerData
        })
    }
}

export default withDragDropContext(Basic)
