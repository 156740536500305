/* eslint-disable no-throw-literal */
import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'horaires/';

const findClientsQuery = (itemPerPage = 15, page = 1, search = '', querying = '') => {
    return DATA_API_URL + 'clients?filter[where][or][0][client][like]=%'+search+'%' +
        '&filter=[where][client][options]=i' +
        '&filter[where][or][1][name][like]=%'+search+'%' +
        '&filter=[where][name][options]=i' +
        '&filter[where][or][2][address][like]=%'+search+'%' +
        '&filter=[where][address][options]=i' +
        '&filter[where][or][3][email][like]=%'+search+'%' +
        '&filter=[where][email][options]=i' +
        '&filter[where][or][4][tel][like]=%'+search+'%' +
        '&filter=[where][tel][options]=i' +
        '&filter[where][or][5][website][like]=%'+search+'%' +
        '&filter=[where][website][options]=i' +
        '&filter[limit]=' + itemPerPage + '&filter[skip]=' + (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0);
}

const findLieuxByClientQuery = (clientId, itemPerPage = 15, page = 1, search = '', querying = '') => {

    let params = {
        where : {
            and : [
                {
                    clientId : clientId?clientId:-1
                },
                {
                    or : [
                        {'name': {like: '%'+search+'%'}},
                        {'address': {like: '%'+search+'%'}},
                        {'tel': {like: '%'+search+'%'}},
                    ]
                }
            ]
        },
        limit: itemPerPage,
        skip: (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0)
    }
    return JSON.stringify(params)
}

const findEmployeByLieuAffectationQuery = (lieuAffectationId,itemPerPage = 15, page = 1, search="") => {
    let params = {
        where : {
            'lieuAffectationId': {eq: lieuAffectationId?lieuAffectationId:-1},
        },
        include:[
            {
                relation: "employe"
            }
        ],
        limit: itemPerPage,
        skip: (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0)
    }
    return JSON.stringify(params)
}

const findPositionsByEmployeAffectationQuery = (employeAffectationId,itemPerPage = 15, page = 1, search="") => {
    let params = {
        where : {
            'employeAffectationId': {eq: employeAffectationId?employeAffectationId:-1},
        },
        include:[
            {
                relation: "position"
            }
        ],
        limit: itemPerPage,
        skip: (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0)
    }
    return JSON.stringify(params)
}


const HoraireRepository = {
    findClients : (page = 1, itemPerpage = 10, search = '') => {
        return axios
            .get(findClientsQuery(itemPerpage, page, search))
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    findLieuxAffectationByClient : (clientId, page = 1, itemPerpage = 10, search = '') => {
        return axios
            .get(DATA_API_URL + "lieux",{
                params:{
                    filter:findLieuxByClientQuery(clientId,itemPerpage, page, search)
                }
            })
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    }
    ,
    findEmployeByLieuAffectation : (lieuAffectationId,page = 1, itemPerpage = 10, search = '') => {
        return axios
            .get(DATA_API_URL + "employes",{
                params:{
                    filter:findEmployeByLieuAffectationQuery(lieuAffectationId,itemPerpage, page, search)
                }
            })
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },

    findPositionsByEmployeAffectation : (employeAffectationId,page = 1, itemPerpage = 10, search = '') => {
        return axios
            .get( DATA_API_URL + "positions",{
                params: {
                    filter:findPositionsByEmployeAffectationQuery(employeAffectationId,itemPerpage, page, search)
                }
            })
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },

    findConges : ()=>{
        return axios
            .get(DATA_API_URL + 'conges')
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },

    findAll : () => {
        return axios
            .get(DATA_API_URL + 'get')
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },

    add : (item) => {
        return axios
            .post(DATA_API_URL + "create", item).then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    edit: (item) => {
        return axios
            .put(DATA_API_URL + "create/" + item.id, item).then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    flush: (item) => {
        if (item.id) {
            return HoraireRepository.edit(item)
        } else {
            delete item['id']
            return HoraireRepository.add(item);
        }
    },
    find : (id) => {
        return axios
            .get(DATA_API_URL + '/' + id)
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    remove : (id) => {
        return axios.delete(DATA_API_URL + '/del/' + id).then((response) => {
            return id
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    }
}


export default HoraireRepository;
