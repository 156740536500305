/* eslint-disable react/prop-types,react/no-unescaped-entities */
import React, {Fragment} from 'react'
import {DIALOG_TYPE} from "./constants";
import AppModalComponent from "./AppModalComponent";


const ConfirmContent = ({bulk = false}) => <>
    <div className="icon-box">
        <i className="ik ik-x-circle"/>
    </div>
    <h4 className="w-100">Êtes-vous sûr?</h4>
    {bulk ? <p>Voulez-vous vraiment supprimer ces enregistrements? Ce processus ne peut être annulé.</p> :
        <p>Voulez-vous vraiment supprimer cet enregistrement? Ce processus ne peut être annulé.</p>}
</>;

const SessionExpiredContent = () => <>
    <div className="icon-box">
        <i className="bs bx-shield-alt"/>
    </div>
    <h4 className="w-100">Votre session a expiré</h4>
    <p>Vous n'êtes pas authorisé à accéder à la ressource demandée. Pour des mesures de sécurité, nous vous avons
        déconnecté. Veuillez vous reconnecter.</p>
</>;

const UnAuthorizeContent = () => <>
    <div className="icon-box">
        <i className="bs bxs-sort-alt"/>
    </div>
    <h4 className="w-100">Accès non autorisé</h4>
    <p>Vous n'êtes pas authorisé à accéder à la ressource demandée. Pour des mesures de sécurité, nous vous avons
        déconnecté. Veuillez vous reconnecter.</p>
</>;

const ServerErrorContent = ({message = ''}) => <>
    <div className="icon-box">
        <i className="bs bs-select"/>
    </div>
    <h4 className="w-100">Une erreur s'est produite</h4>
    <p>Nous avons détecté une erreur lors de l'exécution de votre requête.</p>
    {message.length > 0 && <code>{message}</code>}
</>;

const AlertContent = ({title = "Ressource introuvable", message = "La ressource demandée est introuvable. Elle semble avoir été supprimée ou déplacée"}) => <>
    <div className="icon-box">
        <i className="ik ik-alert-triangle"/>
    </div>
    <h4 className="w-100">{title}</h4>
    <p>{message}.</p>
</>;

const SuccessContent = ({message = ""}) => <>
    <div className="icon-box">
        <i className="ik ik-check" style={{color: '#0070c0'}}/>
    </div>
    <h4 className="w-100">Envoi terminé</h4>
    <p>Votre demande à été envoyé avec succes, connecté vous a votre espace d'administration pour suivre son
        evolution</p>
    {message.length > 0 && <code style={{color: '#0070c0'}}>{message}</code>}
</>;

const ConfirmTextContent = ({message = ""}) => <>
    <div className="icon-box">
        <i className="ik ik-check" style={{color: '#0070c0'}}/>
    </div>
    <h4 className="w-100">Oppération</h4>
    {message.length > 0 && <code style={{color: '#0070c0'}}>{message}</code>}
</>;

/**
 *
 * Affiche une modale de confirmation d'action.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const DialogBoxComponent = (props) => {
    const {handleClose = {}, handleConfirm, show, message = "", children, title = '', type} = props;
    let className = ' ';
    let confirmText = '';
    let boxTitle = '';
    let content = '';
    let closeTitle = 'Annuler';
    let showConfirmButton = true;
    switch (type) {
        case DIALOG_TYPE.CONFIRM: {
            className += 'confirm-dialog';
            confirmText = 'Confirmer';
            content = <ConfirmTextContent message={message}/>;
            break;
        }
        case DIALOG_TYPE.CONFIRM_DELETION: {
            className += 'confirm-dialog';
            confirmText = 'Confirmer la suppression';
            boxTitle = 'Opération de suppression';
            content = <ConfirmContent/>;
            break;
        }
        case DIALOG_TYPE.CONFIRM_BULK_DELETION: {
            className += 'confirm-dialog';
            confirmText = 'Confirmer la suppression';
            boxTitle = 'Opération de suppression';
            content = <ConfirmContent bulk={true}/>;
            break;
        }
        case DIALOG_TYPE.ALERT: {
            className += 'alert-dialog';
            showConfirmButton = false;
            closeTitle = 'Fermer et retourner';
            content = <AlertContent message={message}/>;
            break;
        }
        case DIALOG_TYPE.SESSION_EXPIRED: {
            className += 'alert-session-expired';
            confirmText = 'Me reconnecter';
            content = <SessionExpiredContent/>;
            break;
        }
        case DIALOG_TYPE.AUTH_REQUIRED: {
            className += 'alert-session-expired';
            confirmText = 'Me reconnecter';
            content = <UnAuthorizeContent/>;
            break;
        }
        case DIALOG_TYPE.SERVER_ERROR: {
            className += 'alert-session-expired';
            confirmText = 'Me reconnecter';
            content = <ServerErrorContent message={message}/>;
            showConfirmButton = false;
            closeTitle = 'Fermer';
            break;
        }
        case DIALOG_TYPE.SUCCESS: {
            className += 'success-dialog';
            content = <SuccessContent message={message}/>;
            showConfirmButton = false;
            closeTitle = 'Fermer';
            break;
        }
        default:
    }

    const closeDialog = () => {
        if (handleClose) {
            handleClose(); //Appel du callback lorsque le dialog est fermé.
        }
    };
    const confirmDialog = (e) => {
        // if (type == DIALOG_TYPE.SESSION_EXPIRED) {
        //     window.location.replace("/login");
        // } else {
        if (handleConfirm) {
            handleConfirm(e); //Appel du callback lorsque le bouton confirm est cliqué
        }
        // }
    };
    return (
        <Fragment>
            <AppModalComponent
                enabledLoading={false}
                handleClose={closeDialog}
                show={show}
                className={'dialog ' + className}
                cssConfirmBtnClass={className}
                saveTitle={confirmText}
                closeTitle={closeTitle}
                enableConfirmBtn={showConfirmButton}
                handleClickSaveButton={(e) => confirmDialog(e)}
                title={boxTitle}>
                <div className="row no-gutters pt-4">
                    <div className="col-12">
                        <div className="main-form-card pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="content-form-item">
                                        <div className="tab-content">
                                            <div id="leaner-identity" className="tab-pane active">
                                                <div className="mx-auto text-center box-content">
                                                    {content}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppModalComponent>
        </Fragment>
    )
};

export default DialogBoxComponent;
