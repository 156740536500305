import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Horaires from './Horaires/Horaires';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
    <Router>
        <Routes>
            <Route path='/horaires' element={<Horaires/>} />
        </Routes>
    </Router>, document.getElementById('root'));
registerServiceWorker();