/**
 * Helper pour manager les champs select autocomplete des formulaires.
 * Dans une mésure du possible, ce helper peut être modularisé si les méthode prennent du nombre.
 */
import HoraireRepository from "../Horaires/Repository/HoraireRepository";

const Autocomplete = {
    clients: (inputValue) => {
        return new Promise(resolve => resolve(HoraireRepository.findClients(1,10, inputValue ? inputValue : "").then((data) => data.items)))
    },

    lieuxAffectationByClient: (clientId, inputValue) => {
        return new Promise(resolve => resolve(HoraireRepository.findLieuxAffectationByClient(clientId,1,10, inputValue ? inputValue : "").then((data) => data.items)))
    },

    employeByLieuAffectation: (lieuAffectationId, inputValue) => {
        return new Promise(resolve => resolve(HoraireRepository.findEmployeByLieuAffectation(lieuAffectationId,1,10, inputValue ? inputValue : "").then((data) => {
            let newData = data.items.map(elt =>{
                let newElt = elt; 
                newElt["employeName"] = elt.employe.name; 
                return newElt;
             });
             return newData;
        })))
    },  

    positionsByEmployeAffectation: (employeAffectationId, inputValue) => {
        return new Promise(resolve => resolve(HoraireRepository.findPositionsByEmployeAffectation(employeAffectationId,1,10, inputValue ? inputValue : "").then((data) => {
            let newData = data.items.map(elt =>{
                let newElt = elt; 
                newElt["positionName"] = elt.position.name; 
                return newElt;
             });
             return newData;
        })))
    },
};

export default Autocomplete;
